<template>
  <div class="content">
    <!-- 面包屑 -->
    <div class="crumb">
      <Breadcrumb separator=">">
        <Breadcrumb-item :to="{ path: '/' }">悦心车</Breadcrumb-item>
        <Breadcrumb-item :to="{ path: '/carlist' }">车辆列表</Breadcrumb-item>
        <Breadcrumb-item :to="{ path: `/detail?id=${carid}` }"
          >车辆详情</Breadcrumb-item
        >
        <Breadcrumb-item :to="{ path: '/detail/360check' }"
          >保修服务</Breadcrumb-item
        >
      </Breadcrumb>
    </div>
    <h2>{{ title }}</h2>
    <!-- 1 -->
    <div
      class="details-list"
      v-for="(item, index1) in info3"
      :key="index1"
      ref="info3"
    >
      <div class="details-list-title">
        {{ item.title }}
      </div>
      <div class="details-list-specific">
        <div
          class="details-list-specific-item"
          v-for="(item, i) in item.item"
          :key="i"
        >
          <div class="details-list-specific-item-msg">
            {{ item.msg }}
          </div>
          <div
            class="details-list-specific-item-icon"
            :class="item.status == 1 ? 'active' : ''"
          >
            <!-- <img src="../../../static/details_img/true.png" mode="widthFix"></img> -->
          </div>
        </div>
      </div>
    </div>
    <!-- 2 -->
    <div class="details-speed" ref="speed3">
      <div class="details-list-title-speed">
        变速箱系统
      </div>
      <div class="speed-wraper">
        <div
          class="details-list-speed"
          v-for="(item, index2) in speed3"
          :key="index2"
        >
          <div
            class="details-list-speed-item"
            v-for="(item, i) in item"
            :key="i"
          >
            <div class="details-list-speed-item-msg">
              {{ item.msg }}
            </div>
            <div
              class="details-list-speed-item-icon"
              :class="item.status == 1 ? 'active' : ''"
            >
              <!-- <img src="../../../static/details_img/true.png" mode="widthFix"></img> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 3 -->
    <div
      class="details-list"
      v-for="(item, index3) in turn3"
      :key="index3"
      ref="turn3"
    >
      <div class="details-list-title">
        {{ item.title }}
      </div>
      <div class="details-list-specific">
        <div
          class="details-list-specific-item"
          v-for="(item, i) in item.item"
          :key="i"
        >
          <div class="details-list-specific-item-msg">
            {{ item.msg }}
          </div>
          <div
            class="details-list-specific-item-icon"
            :class="item.status == 1 ? 'active' : ''"
          >
            <!-- <img src="../../../static/details_img/true.png" mode="widthFix"></img> -->
          </div>
        </div>
      </div>
    </div>
    <!-- 4 -->
    <div
      class="details-list"
      v-for="(item, index4) in affiliate3"
      :key="index4"
      ref="affiliate3"
    >
      <div class="details-list-title">
        {{ item.title }}
      </div>
      <div class="details-list-specific">
        <div
          class="details-list-specific-item"
          v-for="(item, i) in item.item"
          :key="i"
        >
          <div class="details-list-specific-item-msg">
            {{ item.msg }}
          </div>
          <div
            class="details-list-specific-item-icon"
            :class="item.status == 1 ? 'active' : ''"
          >
            <!-- <img src="../../../static/details_img/true.png" mode="widthFix"></img> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      carid: "", // 车辆id
      id: 0, //基础班
      floor: 0, //楼层
      info3: [],
      speed3: [],
      turn3: [],
      affiliate3: [],
      title: "",
      // 发动机
      info: [
        {
          title: "发动机系统",
          item: [
            {
              msg: "气缸体",
              status: 1
            },
            {
              msg: "气缸盖",
              status: 1
            },
            {
              msg: "活塞",
              status: 1
            },
            {
              msg: "活塞销",
              status: 1
            },
            {
              msg: "活塞环",
              status: 1
            },
            {
              msg: "曲轴",
              status: 1
            },
            {
              msg: "曲轴瓦",
              status: 1
            },
            {
              msg: "曲轴油封",
              status: 1
            },
            {
              msg: "凸轮轴",
              status: 1
            },
            {
              msg: "凸轮轴油封",
              status: 1
            },
            {
              msg: "平衡轴",
              status: 1
            },
            {
              msg: "VVT轮",
              status: 1
            },
            {
              msg: "正时皮带或链条",
              status: 1
            },
            {
              msg: "正时皮带或链涨紧器",
              status: 1
            },
            {
              msg: "正时链盖",
              status: 1
            },
            {
              msg: "正时皮带涨紧轮",
              status: 1
            },
            {
              msg: "连杆瓦",
              status: 1
            },
            {
              msg: "止推瓦",
              status: 1
            },
            {
              msg: "连杆",
              status: 1
            },
            {
              msg: "气门",
              status: 1
            },
            {
              msg: "气门导管",
              status: 1
            },
            {
              msg: "气门弹簧",
              status: 1
            },
            {
              msg: "气门油封",
              status: 1
            },
            {
              msg: "气门座",
              status: 1
            },
            {
              msg: "气门固定器",
              status: 1
            },
            {
              msg: "气门液压顶柱",
              status: 1
            },
            {
              msg: "气门推杆",
              status: 1
            },
            {
              msg: "气门室盖",
              status: 1
            },
            {
              msg: "曲轴皮带盘",
              status: 1
            },
            {
              msg: "带谐振平衡器的飞轮盘",
              status: 1
            },
            {
              msg: "气缸套",
              status: 1
            },
            {
              msg: "机油泵",
              status: 1
            },
            {
              msg: "机油泵链条",
              status: 1
            },
            {
              msg: "进气歧管",
              status: 1
            },
            {
              msg: "排气歧管",
              status: 1
            },
            {
              msg: "油封和衬垫",
              status: 1
            },
            {
              msg: "机油散热器",
              status: 1
            },
            {
              msg: "机滤座",
              status: 1
            },
            {
              msg: "发动机油底壳",
              status: 1
            }
          ]
        }
      ],
      // 基础版
      info2: [
        {
          title: "发动机系统",
          item: [
            {
              msg: "气缸体",
              status: 1
            },
            {
              msg: "气缸盖",
              status: 1
            },
            {
              msg: "活塞",
              status: 1
            },
            {
              msg: "活塞销",
              status: 1
            },
            {
              msg: "活塞环",
              status: 1
            },
            {
              msg: "曲轴",
              status: 1
            },
            {
              msg: "曲轴瓦",
              status: 1
            },
            {
              msg: "曲轴油封",
              status: 0
            },
            {
              msg: "凸轮轴",
              status: 1
            },
            {
              msg: "凸轮轴油封",
              status: 0
            },
            {
              msg: "平衡轴",
              status: 1
            },
            {
              msg: "VVT轮",
              status: 0
            },
            {
              msg: "正时皮带或链条",
              status: 0
            },
            {
              msg: "正时皮带或链涨紧器",
              status: 0
            },
            {
              msg: "正时链盖",
              status: 0
            },
            {
              msg: "正时皮带涨紧轮",
              status: 0
            },
            {
              msg: "连杆瓦",
              status: 1
            },
            {
              msg: "止推瓦",
              status: 1
            },
            {
              msg: "连杆",
              status: 1
            },
            {
              msg: "气门",
              status: 1
            },
            {
              msg: "气门导管",
              status: 1
            },
            {
              msg: "气门弹簧",
              status: 1
            },
            {
              msg: "气门油封",
              status: 0
            },
            {
              msg: "气门座",
              status: 0
            },
            {
              msg: "气门固定器",
              status: 1
            },
            {
              msg: "气门液压顶柱",
              status: 0
            },
            {
              msg: "气门推杆",
              status: 0
            },
            {
              msg: "气门室盖",
              status: 0
            },
            {
              msg: "曲轴皮带盘",
              status: 1
            },
            {
              msg: "带谐振平衡器的飞轮盘",
              status: 1
            },
            {
              msg: "气缸套",
              status: 0
            },
            {
              msg: "机油泵",
              status: 1
            },
            {
              msg: "机油泵链条",
              status: 0
            },
            {
              msg: "进气歧管",
              status: 1
            },
            {
              msg: "排气歧管",
              status: 0
            },
            {
              msg: "油封和衬垫",
              status: 0
            },
            {
              msg: "机油散热器",
              status: 0
            },
            {
              msg: "机滤座",
              status: 0
            },
            {
              msg: "发动机油底壳",
              status: 0
            }
          ]
        }
      ],
      // 变速箱
      speed: [
        [
          {
            msg: "自动变速箱",
            status: 1
          },
          {
            msg: "全部内部润滑机件",
            status: 1
          },
          {
            msg: "油封和垫圈",
            status: 1
          },
          {
            msg: "换档连接杆和线缆",
            status: 1
          },
          {
            msg: "电磁线圈",
            status: 1
          },
          {
            msg: "变矩器（耦合器）",
            status: 1
          },
          {
            msg: "传动变速器",
            status: 1
          },
          {
            msg: "真空调整器",
            status: 1
          },
          {
            msg: "轴承",
            status: 1
          },
          {
            msg: "金属液压管路",
            status: 1
          },
          {
            msg: "变速器冷却器",
            status: 1
          },
          {
            msg: "磨擦片",
            status: 1
          },
          {
            msg: "制动钢片",
            status: 1
          },
          {
            msg: "离合器毂",
            status: 1
          },
          {
            msg: "制动毂",
            status: 1
          },
          {
            msg: "电子换档控制单元",
            status: 1
          },
          {
            msg: "变速箱阀体",
            status: 1
          },
          {
            msg: "变速箱电脑控制单元",
            status: 1
          },
          {
            msg: "变速箱壳体",
            status: 1
          },
          {
            msg: "驻车空挡位置开关",
            status: 1
          },
          {
            msg: "转速传感器",
            status: 1
          },
          {
            msg: "变速箱油温度传感器",
            status: 1
          },
          {
            msg: "变速箱油底壳",
            status: 1
          },
          {
            msg: "变速箱油散热器控制阀",
            status: 1
          },
          {
            msg:
              "*（变速箱和分动箱为一体的，由于分动箱问题导致变速箱维修不在鉴定保障范围）",
            status: 1
          }
        ],
        [
          {
            msg: "手动变速箱",
            status: 1
          },
          {
            msg: "全部内部润滑机件",
            status: 1
          },
          {
            msg: "离合器总泵",
            status: 1
          },
          {
            msg: "离合器分泵",
            status: 1
          },
          {
            msg: "轴承",
            status: 1
          },
          {
            msg: "齿轮和轴",
            status: 1
          },
          {
            msg: "同步器",
            status: 1
          },
          {
            msg: "油封和垫圈",
            status: 1
          },
          {
            msg: "挂挡机构",
            status: 1
          },
          {
            msg: "变速器壳",
            status: 1
          },
          {
            msg: "液压管路",
            status: 1
          },
          {
            msg: "离合器踏板组件",
            status: 1
          },
          {
            msg: "总泵油壶",
            status: 1
          },
          {
            msg: "换挡杆部件",
            status: 1
          },
          {
            msg:
              "*（不包括离合器压盘、离合器片、离合器分离轴承、因倒档齿轮损坏而产生的其他内部齿轮部件的损坏）",
            status: 1
          }
        ]
      ],
      // 基础版
      speed2: [
        [
          {
            msg: "自动变速箱",
            status: 1
          },
          {
            msg: "全部内部润滑机件",
            status: 0
          },
          {
            msg: "油封和垫圈",
            status: 0
          },
          {
            msg: "换档连接杆和线缆",
            status: 0
          },
          {
            msg: "电磁线圈",
            status: 0
          },
          {
            msg: "变矩器（耦合器）",
            status: 1
          },
          {
            msg: "传动变速器",
            status: 0
          },
          {
            msg: "真空调整器",
            status: 0
          },
          {
            msg: "轴承",
            status: 1
          },
          {
            msg: "金属液压管路",
            status: 1
          },
          {
            msg: "变速器冷却器",
            status: 0
          },
          {
            msg: "磨擦片",
            status: 0
          },
          {
            msg: "制动钢片",
            status: 1
          },
          {
            msg: "离合器毂",
            status: 1
          },
          {
            msg: "制动毂",
            status: 1
          },
          {
            msg: "电子换档控制单元",
            status: 0
          },
          {
            msg: "变速箱阀体",
            status: 1
          },
          {
            msg: "变速箱电脑控制单元",
            status: 1
          },
          {
            msg: "变速箱壳体",
            status: 1
          },
          {
            msg: "驻车空挡位置开关",
            status: 0
          },
          {
            msg: "转速传感器",
            status: 0
          },
          {
            msg: "变速箱油温度传感器",
            status: 0
          },
          {
            msg: "变速箱油底壳",
            status: 0
          },
          {
            msg: "变速箱油散热器控制阀",
            status: 0
          },
          {
            msg:
              "*（变速箱和分动箱为一体的，由于分动箱问题导致变速箱维修不在鉴定保障范围）",
            status: 1
          }
        ],
        [
          {
            msg: "手动变速箱",
            status: 1
          },
          {
            msg: "全部内部润滑机件",
            status: 0
          },
          {
            msg: "离合器总泵",
            status: 0
          },
          {
            msg: "离合器分泵",
            status: 0
          },
          {
            msg: "轴承",
            status: 1
          },
          {
            msg: "齿轮和轴",
            status: 1
          },
          {
            msg: "同步器",
            status: 1
          },
          {
            msg: "油封和垫圈",
            status: 0
          },
          {
            msg: "挂挡机构",
            status: 0
          },
          {
            msg: "变速器壳",
            status: 1
          },
          {
            msg: "液压管路",
            status: 0
          },
          {
            msg: "离合器踏板组件",
            status: 0
          },
          {
            msg: "总泵油壶",
            status: 0
          },
          {
            msg: "换挡杆部件",
            status: 0
          },
          {
            msg:
              "*（不包括离合器压盘、离合器片、离合器分离轴承、因倒档齿轮损坏而产生的其他内部齿轮部件的损坏）",
            status: 1
          }
        ]
      ],
      // 转向系统
      turn: [
        {
          title: "转向系统",
          item: [
            {
              msg: "转向机总成",
              status: 1
            },
            {
              msg: "转向助力泵",
              status: 1
            },
            {
              msg: "转向压力阀门",
              status: 1
            },
            {
              msg: "助力活塞总成",
              status: 1
            },
            {
              msg: "齿轮齿条",
              status: 1
            },
            {
              msg: "轴套／轴承",
              status: 1
            },
            {
              msg: "中央连接",
              status: 1
            },
            {
              msg: "转向随动臂",
              status: 1
            },
            {
              msg: "转向臂",
              status: 1
            },
            {
              msg: "油封和垫圈",
              status: 1
            },
            {
              msg: "转向柱",
              status: 1
            },
            {
              msg: "转向柱连接",
              status: 1
            },
            {
              msg: "转向柱轴",
              status: 1
            },
            {
              msg: "转向减震器",
              status: 1
            },
            {
              msg: "拉杆球头",
              status: 1
            },
            {
              msg: "转向节臂",
              status: 1
            },
            {
              msg: "转向助力泵皮带轮",
              status: 1
            },
            {
              msg: "转向阻尼器",
              status: 1
            },
            {
              msg: "拉杆调整套",
              status: 1
            },
            {
              msg: "蜗轮",
              status: 1
            },
            {
              msg: "蜗杆",
              status: 1
            },
            {
              msg: "中央锁弹簧",
              status: 1
            },
            {
              msg: "阶段控制单元和步进电机",
              status: 1
            },
            {
              msg: "速度感应器",
              status: 1
            },
            {
              msg: "电子驱动液压泵和电机",
              status: 1
            }
          ]
        }
      ],
      // 基础版
      turn2: [
        {
          title: "转向系统",
          item: [
            {
              msg: "转向机总成",
              status: 1
            },
            {
              msg: "转向助力泵",
              status: 1
            },
            {
              msg: "转向压力阀门",
              status: 0
            },
            {
              msg: "助力活塞总成",
              status: 0
            },
            {
              msg: "齿轮齿条",
              status: 0
            },
            {
              msg: "轴套／轴承",
              status: 0
            },
            {
              msg: "中央连接",
              status: 0
            },
            {
              msg: "转向随动臂",
              status: 0
            },
            {
              msg: "转向臂",
              status: 0
            },
            {
              msg: "油封和垫圈",
              status: 0
            },
            {
              msg: "转向柱",
              status: 1
            },
            {
              msg: "转向柱连接",
              status: 0
            },
            {
              msg: "转向柱轴",
              status: 1
            },
            {
              msg: "转向减震器",
              status: 0
            },
            {
              msg: "拉杆球头",
              status: 0
            },
            {
              msg: "转向节臂",
              status: 0
            },
            {
              msg: "转向助力泵皮带轮",
              status: 0
            },
            {
              msg: "转向阻尼器",
              status: 0
            },
            {
              msg: "拉杆调整套",
              status: 0
            },
            {
              msg: "蜗轮",
              status: 0
            },
            {
              msg: "蜗杆",
              status: 0
            },
            {
              msg: "中央锁弹簧",
              status: 0
            },
            {
              msg: "阶段控制单元和步进电机",
              status: 0
            },
            {
              msg: "速度感应器",
              status: 0
            },
            {
              msg: "电子驱动液压泵和电机",
              status: 0
            }
          ]
        }
      ],
      // 附属系统
      affiliate: [
        {
          title: "附属系统",
          item: [
            {
              msg: "空调泵",
              status: 1
            },
            {
              msg: "车窗升降器开关",
              status: 1
            },
            {
              msg: "车窗升降器电机",
              status: 1
            },
            {
              msg: "车窗升降器支架",
              status: 1
            },
            {
              msg: "天窗开关",
              status: 1
            },
            {
              msg: "天窗电机",
              status: 1
            },
            {
              msg: "天窗支架",
              status: 1
            }
          ]
        }
      ],
      // 基础版
      affiliate2: [
        {
          title: "附属系统",
          item: [
            {
              msg: "空调泵",
              status: 0
            },
            {
              msg: "车窗升降器开关",
              status: 0
            },
            {
              msg: "车窗升降器电机",
              status: 0
            },
            {
              msg: "车窗升降器支架",
              status: 0
            },
            {
              msg: "天窗开关",
              status: 0
            },
            {
              msg: "天窗电机",
              status: 0
            },
            {
              msg: "天窗支架",
              status: 0
            }
          ]
        }
      ]
    };
  },
  created() {
    this.carid = this.$route.query.carid;
    this.id = this.$route.query.id;

    if (this.id == 0) {
      this.title = "尊享版";
      this.info3 = this.info;
      this.speed3 = this.speed;
      this.turn3 = this.turn;
      this.affiliate3 = this.affiliate;
    } else {
      this.title = "基础版";
      this.info3 = this.info2;
      this.speed3 = this.speed2;
      this.turn3 = this.turn2;
      this.affiliate3 = this.affiliate2;
    }
  },
  mounted() {
    this.floor = this.$route.query.floor;
    //楼层跳转
    if (this.floor == 0) {
      window.scroll({
        top: this.$refs.info3[0].offsetTop,
        left: 0,
        behavior: "smooth"
      });
    } else if (this.floor == 1) {
      window.scroll({
        top: this.$refs.speed3.offsetTop,
        left: 0,
        behavior: "smooth"
      });
    } else if (this.floor == 2) {
      window.scroll({
        top: this.$refs.turn3[0].offsetTop,
        left: 0,
        behavior: "smooth"
      });
    } else {
      window.scroll({
        top: this.$refs.affiliate3[0].offsetTop,
        left: 0,
        behavior: "smooth"
      });
    }
  },
  methods: {}
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 15px;
  box-sizing: border-box;
  // 1
  .crumb {
    width: 100%;
    height: 20px;
    margin-top: 50px;
    /deep/ .ivu-breadcrumb {
      color: #333;
      font-weight: 500;
      .ivu-breadcrumb-item-separator {
        color: #333;
        font-weight: 500;
      }
    }

    .ivu-breadcrumb > span:last-child {
      color: #333;
      font-weight: 500;
    }
  }
  h2 {
    text-align: center;
    margin: 30px 0;
  }
  .details-list {
    width: 100%;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    box-sizing: border-box;
    font-size: 14px;
    color: #666666;

    .details-list-title {
      width: 100%;
      height: 50px;
      background-color: #f0f0f0;
      line-height: 50px;
      text-align: center;
      border-bottom: 1px solid #ddd;
      box-sizing: border-box;
    }

    .details-list-specific {
      width: 100%;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid #ddd;
      box-sizing: border-box;

      .details-list-specific-item {
        width: 50%;
        height: auto;
        padding: 0 15px;
        box-sizing: border-box;
        line-height: 1.6;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ddd;

        .details-list-specific-item-msg {
          height: 100%;
          padding: 15px 0;
          box-sizing: border-box;
          width: calc(100% - 17px);
          display: flex;
          align-items: center;
        }

        .details-list-specific-item-icon {
          width: 17px;
          height: 17px;
          display: flex;
          justify-content: center;
          align-items: center;
          // background-color: red;
          background-image: url("../../../static/detail/false.png");
          background-size: cover;
        }
        .active {
          width: 17px;
          height: 17px;
          display: flex;
          justify-content: center;
          align-items: center;
          // background-color: red;
          background-image: url("../../../static/detail/true.png");
          background-size: cover;
        }

        .details-list-specific-item-icon > img {
          width: 100%;
        }
      }

      .details-list-specific-item:nth-child(odd) {
        border-right: 1px solid #ddd;
        box-sizing: border-box;
      }
    }
  }
  .details-list:first-child {
    border-top: 1px solid #ddd;
  }
  // 2
  .details-speed {
    width: 100%;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
    color: #666666;
    box-sizing: border-box;
    .details-list-title-speed {
      width: 100%;
      height: 50px;
      background-color: #f0f0f0;
      line-height: 50px;
      text-align: center;
      border-bottom: 1px solid #ddd;
      box-sizing: border-box;
    }
    .speed-wraper {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .details-list-speed {
        width: 50%;
        .details-list-speed-item {
          width: 100%;
          height: auto;
          padding: 0 15px;
          box-sizing: border-box;
          line-height: 1.6;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #ddd;
          border-right: 1px solid #ddd;
          .details-list-speed-item-msg {
            height: 100%;
            padding: 15px 0;
            box-sizing: border-box;
            width: calc(100% - 17px);
            display: flex;
            align-items: center;
          }
          .details-list-speed-item-icon {
            width: 17px;
            height: 17px;
            display: flex;
            justify-content: center;
            align-items: center;
            // background-color: red;
            background-image: url("../../../static/detail/false.png");
            background-size: cover;
          }
          .active {
            width: 17px;
            height: 17px;
            display: flex;
            justify-content: center;
            align-items: center;
            // background-color: red;
            background-image: url("../../../static/detail/true.png");
            background-size: cover;
          }
          .details-list-speed-item-icon > img {
            width: 100%;
          }
        }
      }
      .details-list-speed:nth-child(even) {
        .details-list-speed-item {
          border-right: none;
        }
      }
      .details-list-speed {
        .details-list-speed-item:first-child {
          justify-content: center;
          .details-list-speed-item-msg {
            text-align: center;
          }
          .details-list-speed-item-icon {
            display: none;
          }
        }
        .details-list-speed-item:last-child {
          justify-content: center;
          .details-list-speed-item-msg {
            text-align: center;
            font-size: 13px;
          }
          .details-list-speed-item-icon {
            display: none;
          }
        }
      }
    }
  }
}
</style>
